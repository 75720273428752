@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


//  colors:
//  black       #020202
//  blue        #3772FF
//  light blue  #8DE4FF
//  green       #B0F8B8
//  grey        #7A7978


* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

img{
  border-radius: 10px;
}

body{
  background-color: white;
  height: auto;
}

.element.aboot{
  height: 500px;
}



#root{
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
}

.content{
  position: relative;
  top: 0px;
}

.element{
  height: auto;
  width: auto;
}

.about-me{
  // overflow: hidden;
  display: flex;
  flex-direction: row;
  position: fixed;
  width: 200%;
  height: 500px;
  top: 0px;
  left: 0px;
  border-radius: 10px;  
  margin-top: 150px;
  padding: 0px;
  justify-content:center;
  background-color: white;
  color: black;
  font-family: 'Montserrat', sans-serif ;

  .my-story{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 600px;
    max-height: 500px;
    margin: 0px 80px 0px 80px;
    .title{
      color: #3519e9;
      font-size: 25px;
      font-weight: 800;
    }
    @keyframes reveal{
      0%{
        opacity: 0;
        transform: translateX(-50%);
      }
      50%{  
        opacity: 0.5;
        transform: translateX(-25%);
      }
      100%{ 
        opacity: 1;
        transform: translateX(-0%);
      }
    }
    .stage.rF{
      animation: reveal 3s;
    }
    .story-top{
      position: relative;
      top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      min-width: 100%;
      height: auto;
      .stage{
        display:Flex;
        flex-direction: row;
        align-items: center;
        width: 250px;
        height: 250px;
        padding: 10px;
        background-color: white;
        border-radius: 10px;
        margin: 0px;
        .pin{
          position: relative;
          top: 48%;
          left: -50%;
          width: 30px;
          height: 30px;
          transform: rotate(45deg);
          border-radius: 0px;
          background-color: #3519e9;
          z-index: 0;
        }
        .info{
          border-radius: 10px;
          overflow: hidden;
          background-size: cover;
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
          width: 100%;
          height: 100%;
          z-index: 1;
          .text{
            color: white;
            background-color: #3519e9;
            width: auto;
            height: 100%;
            text-align: center;
            padding: 5% 5% 5% 5%;
            transition: transform 1s;
            border-radius: 10px ;
            .date{
              font-size: 15px;
              font-weight: 400px;
              margin-bottom: 10px;
            }
            .description{
              font-size: 11px;
              font-weight: 100px;
            }
          }
          .text.onefalse{
            transform: translateY(85%);
          }
          .text.onetrue{
            transform: translateY(0%);
          }
          .text.twofalse{
            transform: translateY(85%);
          }
          .text.twotrue{
            transform: translateY(0%);
          }
          .text.threefalse{
            transform: translateY(85%);
          }
          .text.threetrue{
            transform: translateY(0%);
          }
        }
        .info.three{
          background-position-x: center;
        }
        .info.two{
          background-size: 80%;
          background-repeat: no-repeat;
          background-position-x: center;
          background-position-y: center;
          background-color: rgb(238, 235, 235);
        }
      }
    }
  }
  .next-arrow.true{
    position: absolute;
    left: 47%;
    top: 20%;
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    border-top: 3px solid black;
    border-right: 3px solid black;
    animation: flash 2s infinite;
    z-index: 3;
  }

  @keyframes flash{
    0%{
      opacity: 0.1;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0.1;
    }
  }

  .prev-arrow.true{
    position: absolute;
    left: 1%;
    top: 20%;
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
    border-bottom: 3px solid black;
    border-left: 3px solid black;
    z-index: 3;
    animation: flash 2s infinite 1s;
    z-index: 3;
  }
  .next-arrow.false{
    display: hidden;
  }
  .prev-arrow.false{
    display: hidden;
  }

  .slide-wrapper{
    position: relative;
    top: -100px;
    display: flex;
    flex-direction: row nowrap;
    transition: transform 2s;
    width: 100%;
  }
  .slide-wrapper.rF{
    transform: translateX(-50%);
  }
  .slide-wrapper.lF{
    transform:translateX(0%);
  }

  .intro-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 80px 150px 80px 100px;
    width: 100%;
    max-width: 100%;
    height: 20%;
    top: 100px;
    .welcome-wrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .time-line{
        margin-top: 40px;
        margin-bottom: 0px;
        display: hidden;
        position: absolute;
        left: 12%;
        height: 3px;
        width:75%;
        transition: width 4s;
        background: linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
                    linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
                    linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
        z-index: -1;
        .circle{
          width: 20px;
          height: 20px;
          position: relative;
          left: 100%;
          top: -8px;
          border-radius: 10px;
          background-color: #3519e9;
        }
      }
      .time-line.rF{
        display: block;
        width: 200%;
      }
      .me-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .rgb-circle{
          display: flex;
          align-content: center;
          justify-content: center;
          align-items:center;
          width: 150px;
          height: 150px;
          border-radius: 75px;
          background-image: url(./images/b786dcaf-1dc4-4771-a426-49400d4db96f.jpg);
          background-position: center;
          background-size: 100%;
          min-width: 150px;
          min-height: 150px;
          animation-name: spin;
          animation-duration: 5s;
          div{
            opacity: 0%;
            display: hidden;
            font-size: 90px;
            color: white;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            animation-name: disappear;
            animation-duration: 5s;
          }
          
          @keyframes spin{
            0%{
              opacity: 0%;
              transform:rotateY(0deg);
              background:  
              linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
              linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
              linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
            }
            15%{
              opacity: 100%;
              transform:rotateY(0deg);
              background:  
              linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
              linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
              linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
            }
            30%{
              opacity: 100%;
              transform:rotateY(0deg);
              background:  
              linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
              linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
              linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
            }
            50%{
              transform:rotateY(90deg);
              background:  
              linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
              linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
              linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
            }
            51%{
              transform:rotateY(90deg);
              background-image: url(./images/b786dcaf-1dc4-4771-a426-49400d4db96f.jpg);
              background-position: center;
              background-size: 100%;
            }
            70%{
              transform: rotateY(0deg);
              background-image: url(./images/b786dcaf-1dc4-4771-a426-49400d4db96f.jpg);
              background-position: center;
              background-size: 100%;
            }
            100%{
              transform: rotateY(0deg);
              background-image: url(./images/b786dcaf-1dc4-4771-a426-49400d4db96f.jpg);
              background-position: center;
              background-size: 100%;
            }
          }
          @keyframes disappear{
            0%{
              opacity: 100%;
            }
            50%{
              opacity: 100%;
            }
            51%{
              opacity: 0%;
            }
            100%{
              opacity: 0%;
            }
          }
        }
        .fixed-container{
          overflow: hidden;
          .animate-container{
            overflow: hidden;
              .intro{
                font-size: 50px;
                font-weight: 500;
                animation-duration: 6s;
                animation-name: slideright;
              }
            @keyframes slideright{
              0%{
                transform: translateX(-100%);
              }
              50%{
                transform: translateX(-100%);
              }
              100%{
                transform: translateX(0%);
              }
            }   
          }
        }
        
      }
    }
    .welcome{
      font-Size: 20px;
      font-style: italic;
    }
    .bio{
      margin-top: 50px;
      font-size: 15px;
      width: 80%;
    }
    .links {
      margin-top: 10px;
      a{
        font-size: 20px;
        color: inherit;
        text-decoration: none;
        margin-right: 20px;
      }
      a:hover{
        text-decoration: underline;
      }
    }  
    .scroll-down{
      position: absolute;
      top: 320px;
      left: 45%;
      animation: flash 2s infinite;
    }
  }
}

  .navi-true{
    position: sticky;
    top: 0;
    z-index: 10;
    visibility: hidden;
  }

  .navi-false{
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 10;
    height:60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    padding-right: 40px;
    box-shadow: 0px 2px 3px grey;
    animation-name: fadein;
    animation-duration: 0.5s;
    @keyframes fadein {
      0%{
        transform: translateY(-100%);
      }
      100%{
        transform: translateY(0%)
      }
    }
    .left-nav{
      h1{
        font-size: 40px;
        font-weight: 800;
        color: black;
      }
    }
    .view-port{
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 40px;
      width: 150px;
      right: 20px;
      top: 10px;
      border: 2px solid white;
      border-radius: 5px;
      transition: height 1s;
      box-shadow: 2px 2px 3px grey;
      .right-nav{
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        width: 150px;
        transition: transform 0.5s;
        .about-link, .skills-link, .projects-link, .interests-link{
          transition: transform 0.1s;
        }

        .about-link.active.false{
          transform: translateY(-40);
        }
        .skills-link.active.false{
          transform: translateY(-80px);
        }
        .projects-link.active.false{
          transform: translateY(-120px);
        }
        .interests-link.active.false{
          transform: translateY(-160px);
        }

        .button{
          width: 150px;
          max-width: 150px;
          height: 40px;
          text-align: center;
          color: white;
          background-color: #339989;
          padding:7px 0px 7px 0px;
          font-size: 20px;
        }
        .about{
          font-size: 20px;
          background-color: black;
        }
        .skill{
          font-size: 20px;
          background-color: #3621c0;
        }
        .project{
          font-size: 20px;
          background-color: #A40E4C;
          
        }
        .interest{
          background-color: #339989;
          font-size: 20px;
        }
        .navigation{
          font-weight: 500;
          color: black;
          text-align: center;
          background-color: white;
        }

      }
    }
    .view-port:hover{
      height: 200px;
      position: absolute;
      right: 40px;
      z-index: 1000;
    }
  }


.border-wrapper {
  height: inherit;
  width: auto;
  position: absolute;
  border-radius: 10px;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: row;
  margin: 0px 30px 50px 30px;
  box-shadow: 5px 5px 10px grey;
  .label{
    width: 90px;
    min-width: 90px;
    height: inherit;
    background-image: linear-gradient(to bottom, #3621c0, #4529fa);
    border-radius: 10px 0px 0px 10px;
      .icon{
        color: white;
        position: relative;
        top: 3%;
        left:20%;
        font-size: 40px;
      }
    
  }
  .skills{
    height: inherit;
    max-height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0px 10px 10px 0px ;  
    margin: 0px;
    padding: 50px 50px 10px 50px;
    justify-content: flex-start;
    background-color: white;
    color: #3621c0;
    top: 0; right: 0; bottom: 0; left: 0;
    font-family: 'Montserrat', sans-serif;
    h1{
      font-size: 25px;
      font-weight: 800;
    }
    .icons-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 10px;
      .icon{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        padding:10px;
        margin: 5px;
        border: 0.5px solid #3621c0;
        border-radius: 5px;
        h1{
          margin-top: 5px;
          font-size: 15px;
        }
      }
    }
    .separating-line{
        width: 100%;
        height:1px;
        background-color: white;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .other{
      margin-bottom: 10px;
    }
    .other-software{
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
      flex-wrap: wrap;
      .icon{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        padding:10px;
        margin: 5px;
        border: 0.5px solid #3621c0;
        border-radius: 5px;
        h1{
          margin-top: 5px;
          font-size: 15px;
        }
      }
    }
    
  }
} 

// the blank
.white-cover{
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: white;
}

.my-interests{
  overflow: hidden;
  display: flex;
  position: relative;
  top:  0px;
  height: 600px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  color: white;
  background-color: white;
  width: auto;
  margin: 0px 30px 50px 30px;
  box-shadow: 5px 5px 10px grey;
  
  .label{
    width: 90px;
    min-width: 90px;
    height: 100%;
    background-image: linear-gradient(to bottom,  #339989, #32ccb5);
    border-radius: 10px 0px 0px 10px;
      .icon{
        color: white;
        position: relative;
        top: 3%;
        left:20%;
        font-size: 40px;
      }
      .hover{
        position: relative;
        top: 80%;
        left: 10px;
        width:100%;
        height:auto;
        .box{
          height: 50px;
          width: 70px;
          border: 1px solid white;
          border-radius: 8px;
        }
        .label-mini{
          position: relative;
          top: -50px;
          height: 50px;
          width: 10px;
          border-radius: 8px 0px 0px 8px;
          background-color: white;
        }
        .pointer{
          position: relative;
          top: -80px;
          left: 15px;
          color: white;
          animation: leftright 3s infinite;
        }
        @keyframes leftright {
          0%{
            transform: translateX(0px);
          }
          50%{
            transform: translateX(45px);
          }
          100%{
            transform: translateX(0px);
          }
        }
      }  
  }
  .interests{
    display: flex;
    flex-direction: row;
    text-align: center;
    color: #339989;
    width: 100%;
    .overflow-wrap{
      overflow: hidden;
      width: 100%;
      .border-interests{
        width: 200%;
        display: flex;
        flex-direction: row;
        transition: transform 1s;
        align-content: center;
        .section{
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          justify-content: center;
          width: 50%;
          margin-right: 10%;
          height: auto;
          margin: 5px;
          padding: 0px 20px 0px 20px;
          h1{
            font-size: 25px;
            font-weight: 800;
            padding-top: 10px;
          }
          .list{
            width: 100%;
            height: 100%;
            font-size: 17.5px;
            text-align: left;
            margin-top: 5px;
            display: flex;
            flex-direction: row;
            div{
              display: flex;
              flex-direction: column;
              justify-content:flex-start;
              align-items: center;
              margin-top: 20px;
              list-style: none;
              color: black;
              width: 30%;
              margin: 10px 20px 10px 20px;
              text-align: center;
              font-size: 15px;
              img{
                padding: 10px;
                width: 90%;
                height: 90%;
                object-fit: cover;
              }
              h1{
                font-size: 20px;
                margin-top: 20px;
              }
              h2{
                font-size: 15px;
                margin-top: 10px;
              }
            }
          }
      }
      .section.currently-learning{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .list{
          position: relative;
          top:0%;
          align-items: center;
          h1{
            font-size: 15px;
            font-weight: 800;
          }
          h2{
            font-weight: 400;
          }
        }
      }
      
    }
    
    }
    .right-true{
      transform: translateX(-50%);
      z-index:2000;
    }

    .left-true{
      transform: translateX(0%);
      z-index:2000;
    }
    .scroll-trigger{
      display: hidden;
      position: absolute;
      top: 0;
      left: 9%;
      z-index: 1;
      width: 5%;
      height: 100%;
    }
    .scroll-trigger.right{
      transform: translateX(1700%);
    }
  }
}
.player{
  margin-top: 150px;
}

.projects-wrapper{
  overflow: visible;
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 600px;
  top: 0px;
  height: auto;
  width: auto;
  box-shadow: 5px 5px 10px grey;
  border-radius: 10px;
  margin: 0px 30px 50px 30px;
  
  .label{
    width: 90px;
    min-width: 90px;
    height: inherit;
    background-image: linear-gradient(to bottom, #A40E4C, #db2772);
    border-radius: 10px 0px 0px 10px;
    .icon{
      color: white;
      position: relative;
      top: 3%;
      left:20%;
      font-size: 40px;
      }
    .hover{
      position: relative;
      top: 80%;
      left: 10px;
      width:100%;
      height:auto;
      .box{
        height: 50px;
        width: 70px;
        border: 1px solid white;
        border-radius: 8px;
      }
      .label-mini{
        position: relative;
        top: -50px;
        height: 50px;
        width: 10px;
        border-radius: 8px 0px 0px 8px;
        background-color: white;
      }
      .pointer{
        position: relative;
        top: -80px;
        left: 15px;
        color: white;
        animation: leftright 3s infinite;
      }
      @keyframes leftright {
        0%{
          transform: translateX(0px);
        }
        50%{
          transform: translateX(45px);
        }
        100%{
          transform: translateX(0px);
        }
      }
    }
  }
  .projects{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-content: center;
    text-align: center;
    height: auto;
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    // border: 0.1px solid white;
    background-color: white;
    color: white;
    font-family: 'Montserrat', sans-serif;
    padding-top: 0px;
    padding-bottom: 20px;
    .title{
      height: auto;
      padding-bottom: 10px;
      padding-top: 10px;
      color: #A40E4C;
      font-size: 25px;
      font-weight: 800;
    }
    .overflow-wrap{
      overflow: hidden;
      width: 100%;
      height: 100%;
      .border-projects{
        overflow: hidden;
        display: flex;
        flex-direction: row;
        height: inherit;
        width: 150%;
        margin: 0px;
        transition: transform 1s;
        .project-box{
          height: auto;
          max-height: 100%;
          width: 40%;
          margin-left: 10px;
          margin-right: 10px;
          display: flex;
          border-radius: 10px;
          padding-top: 0px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-family: 'Montserrat', sans-serif;
          color: black;
          border: 1px solid #db2772;
          .title{
            background-color:#A40E4C;
            color: white;
            border-radius: 5px 5px 0px 0px;
            width: 100%;
            height: auto;
          }
          .icons{
            display: flex;
            flex-direction: row;
            height: 50px;
            width: 100px;
            color: #A40E4C;
            z-index: 2;
            justify-content: center;
            align-items: center;
            margin: 5px 5px 5px 5px;
            .deployment-link{
              display: inline-block;
              margin-right: 5px;
              font-size: 40px;
              color: #A40E4C;
              z-index: 2;
            }
            .github-link{
              display: inline-block;
              margin-left: 5px;
              font-size: 40px;
              color: #A40E4C;
              z-index: 2;
            }
          }
          .image-launchpad{
            background-image: url(./images/launchpad1.png);
            width: 100%;
            height: 60%;
            background-size: cover;
            border: 0.5px solid white
          }

          .image-picturest{
            background-image: url(./images/picturest1.png);
            width: 100%;
            height: 60%;
            background-size: cover;
            border: 0.5px solid white;
            background-position: center;
          }
          .image-battleships{
            background-image: url(./images/battleships1.png);
            width: 100%;
            height: 60%;
            background-size: cover;
            border: 0.5px solid white;
            background-position-x: center;
          }
          .image-mynightin{
            background-image: url(./images/mynightin.png);
            width: 100%;
            height: 60%;
            background-size: cover;
            border: 0.5px solid white;
            background-position-x: center;
          }
          .info{
            font-size: 12.5px;
            padding: 20px 20px 0px 20px;
            font-weight: 400;
          }
          .title{
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 800;
            padding: 5px 0px 5px 0px;
          }
        }
        
      }
    }
    .right-true{
      transform: translateX(-35%);
      z-index:2000;
      .project-box{
        .icons{
          z-index: 2000;
          .deployment-link{
            z-index: 2000;
          }
          .github-link{
            z-index: 2000;
          }
        }
      }
    }

    .left-true{
      transform: translateX(0%);
      z-index:2000;
      .project-box{
        .icons{
          z-index: 2000;
          .deployment-link{
            z-index: 2000;
          }
          .github-link{
            z-index: 2000;
          }
        }
      }
    }
    .scroll-trigger{
      display: hidden;
      position: absolute;
      top: 0;
      left: 20;
      z-index: 1;
      width: 5%;
      height: 100%;
      border-radius: 10px;
      transition: background-image 2s;
    }
    .scroll-trigger.right{
      position: absolute;
      left:95%;
    }
    
    .scroll-trigger.right.false{
      background-image: linear-gradient(to left, #ffffff, #db277200);
    }
    .scroll-trigger.left.false{
      background-image: linear-gradient(to right, #fafafa, #db277200);
    }
  }
  
}







//  mobile version






@media only screen and (max-width: 700px) {
  
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }
  
  body{
    background-color: white;
    height: auto;
  }
  
  .about-me{
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: auto;
    top: -70px;
    border-radius: 10px;  
    margin: 0px;
    padding: 0px;
    justify-content: flex-start;
    background-color: white;
    color: black;
    font-family: 'Montserrat', sans-serif ;
  
    .my-story{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 700px;
      max-height: 500px;
      margin: 0px;
      margin-top: 100px;
      @keyframes reveal{
        0%{
          opacity: 0;
          transform: translateX(-50%);
        }
        50%{  
          opacity: 0.5;
          transform: translateX(-25%);
        }
        100%{ 
          opacity: 1;
          transform: translateX(-0%);
        }
      }
      .stage.rF{
        animation: reveal 3s;
      }
      .story-top{
        position: relative;
        top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        min-width: 100%;
        height: auto;
        .stage{
          display:Flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          height: 450px;
          padding: 10px;
          background-color: white;
          border-radius: 10px;
          margin: 0px;
          margin-bottom: 200px;
          .pin{
            display: none;
            position: relative;
            top: 50%;
            left: -50%;
            width: 10px;
            height: 30px;
            border-radius: 5px;
            background-color: #3519e9;
            z-index: 0;
          }
          .info{
            border-radius: 10px;
            overflow: visible;
            background-size: cover;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            width: 100%;
            height: 100%;
            z-index: 1;
            .text{
              position: relative;
              top: 280px;
              color: white;
              background-color: #3519e9;
              width: auto;
              height: 40%;
              text-align: center;
              padding: 5% 5% 5% 5%;
              transition: none;
              border-radius: 10px ;
              .date{
                font-size: 15px;
                font-weight: 400px;
                margin-bottom: 10px;
              }
              .description{
                font-size: 11px;
                font-weight: 100px;
              }
            }
            .text.onefalse{
              transform: translateY(0%);
            }
            .text.onetrue{
              transform: translateY(0%);
            }
            .text.twofalse{
              transform: translateY(0%);
            }
            .text.twotrue{
              transform: translateY(0%);
            }
            .text.threefalse{
              transform: translateY(0%);
            }
            .text.threetrue{
              transform: translateY(0%);
            }
          }
          .info.three{
            background-position-x: center;
          }
          .info.two{
            background-size: 80%;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
          }
        }
      }
    }
    .next-arrow.true{
      display: none;
    }
  
    @keyframes flash{
      0%{
        opacity: 0.1;
      }
      50%{
        opacity: 1;
      }
      100%{
        opacity: 0.1;
      }
    }
  
    .prev-arrow.true{
      display: none;
    }

    .slide-wrapper{
      display: flex;
      flex-direction: column;

    }
  
    .intro-wrapper{
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      margin: 80px 30px 80px 30px;
      width: auto;
      max-width: 100%;
      .scroll-down{
        display: none;
      }
      .welcome-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .me-wrapper{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .time-line{  
            position: absolute;
            left: 23%;
            top: 30%;
            height: 3px;
            width:10px;
            transition: none;
            background: linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
                        linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
                        linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
            z-index: 2;
          }
          .time-line.rF{
            display: block;
            width: 70%;
          }
          .rgb-circle{
            display: flex;
            align-content: center;
            justify-content: center;
            align-items:center;
            width: 150px;
            height: 150px;
            border-radius: 75px;
            background-image: url(./images/b786dcaf-1dc4-4771-a426-49400d4db96f.jpg);
            background-position: center;
            background-size: 100%;
            // margin: 20px 20px 20px 0px;
            min-width: 150px;
            min-height: 150px;
            animation-name: spin;
            animation-duration: 5s;
            div{
              opacity: 0%;
              display: hidden;
              font-size: 90px;
              color: black;
              font-family: 'Montserrat', sans-serif;
              font-weight: 700;
              animation-name: disappear;
              animation-duration: 5s;
            }
            
            @keyframes spin{
              0%{
                opacity: 0%;
                transform:rotateY(0deg);
                background:  
                linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
                linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
                linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
              }
              15%{
                opacity: 100%;
                transform:rotateY(0deg);
                background:  
                linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
                linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
                linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
              }
              30%{
                opacity: 100%;
                transform:rotateY(0deg);
                background:  
                linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
                linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
                linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
              }
              50%{
                transform:rotateY(90deg);
                background:  
                linear-gradient(217deg, #3519e9, rgba(255,0,0,0) 70.71%),
                linear-gradient(127deg, #F15BB5, rgba(0,255,0,0) 70.71%),
                linear-gradient(336deg, #B0F8B8, rgba(0,0,255,0) 70.71%);
              }
              51%{
                transform:rotateY(90deg);
                background-image: url(./images/b786dcaf-1dc4-4771-a426-49400d4db96f.jpg);
                background-position: center;
                background-size: 100%;
              }
              70%{
                transform: rotateY(0deg);
                background-image: url(./images/b786dcaf-1dc4-4771-a426-49400d4db96f.jpg);
                background-position: center;
                background-size: 100%;
              }
              100%{
                transform: rotateY(0deg);
                background-image: url(./images/b786dcaf-1dc4-4771-a426-49400d4db96f.jpg);
                background-position: center;
                background-size: 100%;
              }
            }
            @keyframes disappear{
              0%{
                opacity: 100%;
              }
              50%{
                opacity: 100%;
              }
              51%{
                opacity: 0%;
              }
              100%{
                opacity: 0%;
              }
            }
          }
          .fixed-container{
            overflow: hidden;
            .animate-container{
              overflow: hidden;
                .intro{
                  font-size: 30px;
                  font-weight: 500;
                  animation-duration: 6s;
                  animation-name: slideright;
                }
              @keyframes slideright{
                0%{
                  transform: translateX(-100%);
                }
                50%{
                  transform: translateX(-100%);
                }
                100%{
                  transform: translateX(0%);
                }
              }   
            }
          }
          
        }
      }
      .welcome{
        font-Size: 20px;
        font-style: italic;
        margin-top: 20px;
      }
      .bio{
        margin-top: 50px;
        font-size: 20px;
        width: 100%;
      }
      .links {
        color: white;
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        z-index: 2;
        a{
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          background-color: black;
          padding: 5px;
          width: 100px;
          height: 60px;
          font-size: 20px;
          color: inherit;
          text-decoration: none;
          margin-right: 20px;
          border-radius: 5px;
        }
      }  
    }
  }
  
    .navi-false, .navi-true{
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      font-family: 'Montserrat', sans-serif;
      box-shadow: 0px 2px 3px grey;
      transition: none !important;
      animation: none !important;
      .left-nav{
        h1{
          font-size: 40px;
          font-weight: 800;
          color: black;
        }
      }
      .view-port{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 40px;
        width: auto;
        right: 20px;
        top: 10px;
        border: 2px solid white;
        border-radius: 10px;
        transition: none !important;
        box-shadow: 0px 0px 0px white;
        animation: none !important;
        .right-nav{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-content: center;
          border-radius: 5px;
          width: auto;
          transition: none !important;
          animation: none !important;
          .about-link, .skills-link, .projects-link, .interests-link{
            transition: none;
          }
  
          .about-link.active.false{
            transform: translateY(0px);
          }
          .skills-link.active.false{
            transform: translateY(0px);
          }
          .projects-link.active.false{
            transform: translateY(0px);
          }
          .interests-link.active.false{
            transform: translateY(0px);
          }
          .about-link.active.true{
            transform: translateY(0px);
          }
          .skills-link.active.true{
            transform: translateY(0px);
          }
          .projects-link.active.true{
            transform: translateY(0px);
          }
          .interests-link.active.true{
            transform: translateY(0px);
          }
          .button{
            width: 90px;
            max-width: auto;
            height: 40px;
            text-align: center;
            color: white;
            background-color: #339989;
            padding:7px 0px 7px 0px;
            font-size: 15px;
            animation: none !important;
          }
          .about{
            font-size: 15px;
            background-color: black;
            transition: none !important;
            animation: none !important;
          }
          .skill{
            font-size: 15px;
            background-color: #3621c0;
            transition: none !important;
            animation: none !important;
          }
          .project{
            font-size: 15px;
            background-color: #A40E4C;
            transition: none !important;
            animation: none !important;
          }
          .interest{
            background-color: #339989;
            font-size: 15px;
            transition: none !important;
            animation: none !important;
          }
          .navigation{
            display: none;
            font-weight: 500;
            color: black;
            text-align: center;
            background-color: white;
            animation: none !important;
          }
  
        }
      }
      .view-port:hover{
        height: 40px;
        right: 20px;
        top: 10px;
        z-index: 1000;
      }
    }
  
  
  .border-wrapper {
    position: relative;
    max-height: inherit;
    height: inherit;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 5px 10px grey;
    .label{
      width: 100%;
      min-width: 100%;
      height: inherit;
      background-image: linear-gradient(to bottom, #3621c0, #4529fa);
      border-radius: 10px 10px 0px 0px;

        .icon{
          color: white;
          font-size: auto;
          margin: 10px 10px 10px 20px;
          position: relative;
          left: 0px;
        }
      
    }
    .skills{
      height: inherit;
      max-height: inherit;
      width: inherit;
      display: flex;
      flex-direction: row wrap;
      border-radius: 0px 10px 10px 0px ;  
      margin: 0px;
      padding: 5px 10px 10px 10px;
      justify-content: flex-start;
      background-color: white;
      color: #3621c0;
      font-family: 'Montserrat', sans-serif;
      
      .icons-wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        .icon{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          padding:10px;
          margin: 5px;
          border: 0.5px solid #3621c0;
          border-radius: 5px;
          h1{
            margin-top: 5px;
            font-size: 15px;
          }
        }
      }
      .separating-line{
          width: 100%;
          height:1px;
          background-color: white;
          margin-top: 30px;
          margin-bottom: 30px;
      }
      .other{
        margin-bottom: 10px;
      }
      .other-software{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
  
        .icon{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          padding:10px;
          margin: 5px;
          border: 0.5px solid #3621c0;
          border-radius: 5px;
          h1{
            margin-top: 5px;
            font-size: 15px;
          }
        }
      }
      
    }
  } 
  
  // the blank
  .white-cover{
    display: none;
  }
  
  .my-interests{
    overflow: visible;
    display: flex;
    flex-direction: column;
    height: auto;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    width: auto;
    margin: 10px 10px 10px 10px;
    box-shadow: 5px 5px 10px grey;
    .label{
      width: 100%;
      min-width: 100%;
      height: inherit;
      background-image: linear-gradient(to bottom,  #339989, #32ccb5);
      border-radius: 10px 10px 0px 0px;
        .icon{
          color: white;
          position: relative;
          top: 0;
          left: 0;
          font-size: auto;
          margin: 10px 10px 10px 20px;
        }
      
    }
    .interests{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // margin: 50px 30px 50px 30px;
      text-align: center;
      color: #339989;
      width: 100%;
      height: inherit;
      .overflow-wrap{
        overflow: visible;
        width: inherit;
        height: inherit;
        .border-interests{
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          transition: transform 1s;
          align-content: center;
          align-items: center;
          .line{
            display: none;
            height: 100%;
            width: 1px;
            background-color: #339989;
            margin-right: 15px;
            
          }
          .section{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            margin: 5px;
            // padding: 0px 20px 0px 20px;
            .list{
              width: 100%;
              height: 100%;
              font-size: 17.5px;
              text-align: left;
              margin-top: 5px;
              display: flex;
              flex-direction: column;
              align-content: center;
              align-items: center;
              div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-content: center;
                margin-top: 20px;
                list-style: none;
                color: black;
                width: 90%;
                // margin: 10px 20px 10px 20px;
                text-align: center;
                font-size: 15px;
                img{
                  padding: 10px;
                  width: 90%;
                  height: 90%;
                  object-fit: cover;
                }
                h1{
                  font-size: 20px;
                  margin-top: 20px;
                }
                h2{
                  font-size: 15px;
                  margin-top: 10px;
                }
              }
            }
        }
        .section.currently-learning{
          margin: 0px;
        }
      }
      }
    }
  }
  
  .projects-wrapper{
    overflow: visible;
    display: flex;
    flex-direction: column;
    min-height: 600px;
    height: auto;
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 5px 5px 10px grey;
    border-radius: 10px;
    .label{
      width: 100%;
      min-width: 100%;
      height: inherit;
      background-image: linear-gradient(to bottom, #A40E4C, #db2772);
      border-radius: 10px 10px 0px 0px;
      .icon{
        color: white;
        position: relative;
        top: 0%;
        left: 0%;
        margin: 10px 10px 10px 20px;
        font-size: auto;
        }
    }
    .projects{
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-content: center;
      text-align: center;
      height: auto;
      width: auto;
      border-radius: 10px 10px 10px 10px;
      // border: 0.1px solid white;
      background-color: white;
      color: white;
      font-family: 'Montserrat', sans-serif;
      padding-top: 10px;
      padding-bottom: 10px;
      .overflow-wrap{
        overflow: hidden;
        width: 100%;
        height: 100%;
        .border-projects{
          align-items: center;
          overflow: visible;
          display: flex;
          flex-direction: column;
          height: auto;
          width: 100%;
          margin: 0px;
          padding: 10px;
          transition: transform 1s;
          height: 100%;
          .project-box{
            height: 100%;
            width: 100%;
            margin-left: 5px;
            margin-right: 5px;
            display: flex;
            border-radius: 10px;
            padding-top: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: 'Montserrat', sans-serif;
            color: black;
            border: 1px solid #db2772;
            margin-top: 30px;
            .title{
              background-color:#A40E4C;
              color: white;
              border-radius: 5px 5px 0px 0px;
              width: 100%;
              font-size: 10px;
            }
            .icons{
              display: flex;
              flex-direction: row;
              height: 40px;
              color: #A40E4C;
              z-index: 2;
              margin: 20px 0px 30px 0px;
              .deployment-link{
                font-size: auto;
                color: #A40E4C;
                z-index: 2;
              }
              .github-link{
                font-size: auto;
                color: #A40E4C;
                z-index: 2;
              }
            }
            .image-launchpad{
              background-image: url(./images/launchpad1.png);
              width: 100%;
              height: 350px;
              background-size: cover;
              border: 0.5px solid white
            }
  
            .image-picturest{
              background-image: url(./images/picturest1.png);
              width: 100%;
              height: 350px;
              background-size: cover;
              border: 0.5px solid white;
              background-position: center;
            }
            .image-battleships{
              background-image: url(./images/battleships1.png);
              width: 100%;
              height: 350px;
              background-size: cover;
              border: 0.5px solid white;
              background-position-x: center;
            }
            .image-mynightin{
              background-image: url(./images/mynightin.png);
              width: 100%;
              height: 350px;
              background-size: cover;
              border: 0.5px solid white;
              background-position-x: center;
            }
            .info{
              font-size: 12.5px;
              padding: 20px 20px 0px 20px;
              font-weight: 400;
            }
            .title{
              font-size: 30px;
              margin-bottom: 10px;
              font-weight: 800;
            }
          }
          
        }
      }
    }
    
  }

  .scroll-trigger{
    display: none;
  }
  
  .content{
    position: relative;
    top: 0px;
  }
  .content{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 2500px; 
  }
  .hover{
    display: none;
  }
}

.footer-wrapper{
  position: relative;
  bottom: 0;
  height: 70px;
  width: 100%;
  box-shadow: 0px -2px 3px grey;
  .left-footer{
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
    color: #6c6e6e;
  }
}

.content{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}